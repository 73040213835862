import { ref } from "vue";
import { useApi } from "@/use/api/useApi";
import useNotificationsStore from "@/stores/systemNotifications/useNotificationsStore";

export function useQuickLink() {

  const loader = ref(false);
  const message = ref('');

  function updateLink(id: number) {
    loader.value = true;
    message.value = '';

    return new Promise((resolve, reject) => {
      useApi().security.fetchQuickLink(id)
        .then(() => {
          useNotificationsStore().showSuccess('Быстрая ссылка отправлена пользователю на почту');
          resolve(null)
        })
        .catch(() => message.value = 'Произошла ошибка при обновлении быстрой ссылки')
        .finally(() => loader.value = false)
    })
  }

  return {
    loader,
    message,
    updateLink,
  }
}
